// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bt_kC";
export var caseStudyBackground__lineColor = "bt_kz";
export var caseStudyHead = "bt_kt";
export var caseStudyHead__imageWrapper = "bt_kv";
export var caseStudyProjectsSection = "bt_kD";
export var caseStudyQuote__bgRing = "bt_kx";
export var caseStudyQuote__bottomVideo = "bt_kR";
export var caseStudyVideoReview = "bt_k9";
export var caseStudyVideoReview__bgRing = "bt_lb";
export var caseStudyVideo__ring = "bt_kN";
export var caseStudy__bgDark = "bt_ks";
export var caseStudy__bgLight = "bt_kr";
export var caseStudy__bgLightReverse = "bt_k8";