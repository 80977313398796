// extracted by mini-css-extract-plugin
export var caseStudyVideoReview = "fh_k9";
export var caseStudyVideoReview__bgRings = "fh_Kc";
export var caseStudyVideoReview__clientImage = "fh_Kf";
export var caseStudyVideoReview__container = "fh_Kb";
export var caseStudyVideoReview__logo = "fh_Kq";
export var caseStudyVideoReview__name = "fh_Kr";
export var caseStudyVideoReview__playWrapper = "fh_J3";
export var caseStudyVideoReview__playWrapper_videoReviewImpact = "fh_J5";
export var caseStudyVideoReview__playWrapper_videoReviewIx = "fh_J9";
export var caseStudyVideoReview__playWrapper_videoReviewPmk = "fh_J7";
export var caseStudyVideoReview__playWrapper_videoReviewRef = "fh_J8";
export var caseStudyVideoReview__playWrapper_videoReviewTradeaboat = "fh_J4";
export var caseStudyVideoReview__playWrapper_videoReviewTradervs = "fh_J6";
export var caseStudyVideoReview__text = "fh_Ks";
export var caseStudyVideoReview__title = "fh_Kd";
export var caseStudyVideoReview__videoYoutube = "fh_Kp";
export var caseStudyVideoReview__wrapper = "fh_Kg";
export var caseStudyVideoReview__wrapper_videoReviewImpact = "fh_Kk";
export var caseStudyVideoReview__wrapper_videoReviewIx = "fh_Kn";
export var caseStudyVideoReview__wrapper_videoReviewPmk = "fh_Kl";
export var caseStudyVideoReview__wrapper_videoReviewRef = "fh_Km";
export var caseStudyVideoReview__wrapper_videoReviewTradeaboat = "fh_Kh";
export var caseStudyVideoReview__wrapper_videoReviewTradervs = "fh_Kj";